function SoonSection() {
    return <div className="container">
        <div className="wrapper w-full flex justify-center mt-[185px]">

            <div className="box bg-[#9646fe80] w-full max-w-full md:max-w-[700px] 2xl:max-w-[1200px] rounded-[30px] p-[20px] flex justify-start flex-col items-center">
                <div className="title text-[30px] md:text-[40px] 2xl:text-[65px] text-[white] text-center">(SOON) Featured on</div>

                <div className="flex w-full justify-around items-center flex-wrap gap-[15px] md:gap-0 mt-2">
                    <a href="https://link.tradingmindset.in/mexc" target="_blank" className="image max-w-[40px] md:max-w-[60px] 2xl:max-w-full">
                        <img src="./featured/1.svg" alt="" />
                    </a>
                    <a href="https://link.tradingmindset.in/bitget" target="_blank" className="image max-w-[70px] md:max-w-[80px] 2xl:max-w-full">
                        <img src="./featured/2.svg" alt="" />
                    </a>
                    <a href="" target="_blank" className="image max-w-[70px] md:max-w-[80px] 2xl:max-w-full">
                        <img src="./featured/3.svg" alt="" />
                    </a>
                    <a href="" target="_blank" className="image max-w-[70px] md:max-w-[80px] 2xl:max-w-full">
                        <img src="./featured/4.svg" alt="" />
                    </a>
                </div>
            </div>

        </div>
    </div>
}


export default SoonSection;