function Navigation() {
    return <div className="xsm:hidden xl:block" id="home">
        <div className="flex items-center justify-around uppercase font-bold bg-[#9746FE] text-[white] text-[20px] md:text-[20px] 2xl:text-[26px] p-[20px]">
            <a href="#home" className="item">
                Home
            </a>
            <a href="#project" className="item">
                Project
            </a>
            <a href="#tokenomics" className="item">
                Tokenomics
            </a>
            <a href="#roadmap" className="item">
                Roadmap
            </a>
            <a href="https://t.me/MemeAppa" target="_blank" className="item">
                Telegram Link
            </a>

            <a href="#home" className="border-[2px] border-[#19FB9B] pr-[20px] pl-[20px] pt-[5px] pb-[5px] rounded-[20px] text-[#19FB9B]">
                #SAPPA
            </a>

        </div>
    </div>
}


export default Navigation;