function Footer() {
    return <div className="relative" id="footer">
    
        <div className="container">
            <div className="wrapper relative mt-[36px]">
                <div className="title text-[60px] xl:text-[80px] text-[#9746FE] text-center">SUNAPPA</div>

                <div className="flex w-full justify-around items-center flex-wrap gap-[15px] md:gap-0 mt-2">
                    <div className="socials flex w-full justify-center items-center flex-wrap gap-[15px]">
                        <a href="https://twitter.com/MemeAppa" target="_blank" className="image md:max-w-full max-w-[60px]">
                            <img src="./socials/1.svg" alt="" />
                        </a>
                        <a href="https://t.me/MemeAppa" target="_blank" className="image md:max-w-full max-w-[60px]">
                            <img src="./socials/2.svg" alt="" />
                        </a>
                        <a href="" target="_blank" className="image md:max-w-full max-w-[60px]">
                            <img src="./socials/3.svg" alt="" />
                        </a>
                        <a href="" target="_blank" className="image md:max-w-full max-w-[60px]">
                            <img src="./socials/4.svg" alt="" />
                        </a>
        
                    </div>

                    <div className="copyright text-[#6A6187] text-[20px] md:text-[30px] mt-2 text-center">
                    Copyright 2024. All Rights Reserved
                    </div>
                </div>
            </div>
        </div>

        <img className="absolute object-cover bottom-0 z-[-1] w-full" src="./footercloud.png" alt="" />

    </div>
}

export default Footer;