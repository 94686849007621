function WalletSection() {
    return <div className="relative" id="project">
        <div className="container">

            <div className="wrapper flex flex-wrap justify-center items-center mt-[40px] flex-col-reverse contract:flex-row-reverse">

                <img className="max-w-[300px] md:max-w-[400px] 2xl:max-w-[500px] mt-[40px] mb-[40px] md:mt-0 md:mb-0 floating" src="./images/sunappa_hug.png" alt="" />

                <div className="z-[1] w-full max-w-[850px] sm:mt-10 mt-[40px]">
                    <div className="bg-[#9646fe84] p-[20px] pr-[5px] pl-[5px] rounded-[15px] flex justify-center md:justify-around items-center flex-wrap">
                        <div className="ca text-[13px] md:text-[22px] 2xl:text-[25px] text-[white] overflow-hidden text-nowrap">
                            CA: xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        </div>

                        <div className="copy bg-[#9746FE] text-[15px] md:text-[20px] 2xl:text-[25px] text-[white] hover:cursor-pointer transition-all p-[15px] pr-[25px] pl-[25px] flex justify-center rounded-[20px] mt-[10px] md:mt-0" onClick={(event) => {
                            event.target.style.transform = "scale(0.9)"
                            event.target.innerText = "Copied";
                            navigator.clipboard.writeText("dxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

                            setTimeout(() => {
                                event.target.style.transform = "scale(1)"
                                event.target.innerText = "Copy";
                            }, 1000);
                        }}>
                            Copy
                        </div>
                    </div>

                    <div className="mt-[41px] flex justify-center xl:justify-around flex-wrap gap-5 xl:gap-0 w-full">
                        <a href="" className="text-[#20EEA9] text-[25px] md:text-[30px] 2xl:text-[40px] text-center p-[20px] bg-[#9746FE] rounded-[25px] w-[70%] xl:w-fit max-w-[423px]">Sun Pump</a>
                        <a href="" className="text-[#20EEA9] text-[25px] md:text-[30px] 2xl:text-[40px] text-center p-[20px] bg-[#9746FE] rounded-[25px] w-[70%] xl:w-fit max-w-[423px]">DexScreener</a>
                    </div>
                </div>

            </div>
        </div>

        <img className="absolute object-cover bottom-[35%] xl:bottom-[-56%] z-[-1] w-full" src="./wallet-section-cloud.png" alt="" />
        

    </div>
}

export default WalletSection;