import Appanomics from "./appanomics/Appanomics";
import Hero from "./hero/Hero";
import WalletSection from "./main/WalletSection";
import Navigation from "./navigation/Navigation";
import SoonSection from "./soon/SoonSection";
import Footer from "./footer/Footer";
import AboutSection from "./main/AboutSection"
import Journey from "./journey/Journey";

function App() {
  return (
    <div>
      <Navigation />
      <Hero />
      <AboutSection />
      <WalletSection />
      <Journey />
      <SoonSection />
      <Footer />
    </div>
  );
}

export default App;
