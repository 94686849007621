function Hero() {
    return <div className="relative">
        <div className="container">
            <div className="wrapper flex mt-[30px] flex-wrap justify-center items-center pb-[180px] md:pt-[100px]">
                <div className="left flex flex-col items-start relative md:w-[60%] w-full">
                    <div className="title flex items-center gap-[2px] w-full">
                        <div>
                            <img src="./images/logo.png" className="max-w-[115px] md:max-w-[200px]" alt="" />
                        </div>
                        <div>
                            <div className="text-[#9746FE] text-[53px] sm:text-[85px]">SUNAPPA</div>
                            <span className="text-[#6A6187] text-[23px] sm:text-[45px]">The Last Sunbender</span>
                        </div>
                    </div>

                    <div className="text mt-[50px] text-[#6A6187] text-[30px] md:text-[35px] 2xl:text-[50px] min-w-full md:w-[600px] 2xl:w-[820px]">
                    SUN APPA ($SAPPA) is here to brighten up your crypto experience! Born on the Tron blockchain, $SAPPA is more than just a token—it’s a community, a vibe, and a whole lot of fun. Get ready to join the $SAPPA family and ride the wave of laughter and memes!

                    </div>
                </div>

                <img className="max-w-[300px] md:max-w-[550px] 2xl:max-w-[500px] mt-[40px] md:mt-0 floating" src="./images/sunappa_fly.png" alt="" />
            </div>

        </div>


        <div className="justify-center w-full bottom-0 z-[1] bg-[#d7b5fb] overflow-hidden flex">

            <div class="scrolling_text">
                <div class="text">
                    <span className="text-[32px] text-[white]">#SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA</span>
                    <span className="text-[32px] text-[white]">#SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA</span>
                    <span className="text-[32px] text-[white]">#SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA</span>
                    <span className="text-[32px] text-[white]">#SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA</span>
                </div>
                <div class="text">
                    <span className="text-[32px] text-[white]">#SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA</span>
                    <span className="text-[32px] text-[white]">#SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA</span>
                    <span className="text-[32px] text-[white]">#SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA</span>
                    <span className="text-[32px] text-[white]">#SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA #SAPPA</span>
                </div>
            </div>
        </div>

        <img className="absolute object-cover top-[160px] z-[-1] w-[164px]" src="./cloud-hero1.png" alt="" />
        <img className="absolute object-cover top-[100px] right-0 z-[-1] w-[164px]" src="./cloud-hero2.png" alt="" />
        
        <img className="absolute object-fit top-[300px] z-[-1] w-full" src="./cloud-img1.png" alt="" />
        
    </div>
}

export default Hero;