
function Line() {
    return                     <div className="line md:block hidden">
    <div className="w-[150px] h-[3px] bg-[#9746FE] relative">
        <div>
            <div className="w-[25px] h-[25px] bg-[#9746FE] absolute bottom-[-10px] right-[50%]">

            </div>

            <div class="absolute bottom-[-17px] right-[30%] w-0 h-0 border-t-[21px] border-t-transparent border-b-[18.5px] border-b-transparent border-l-[33px] border-l-[#9746FE]"></div>

        </div>
    </div>
</div>
}

function Journey() {
    return <div className="container">
        <div className="wrapper w-full flex justify-center flex-col items-center mt-[85px]">
            <div className="title text-[50px] xl:text-[82px] text-[#9746FE] text-center">APPA JOURNEY</div>

            <div className="flex items-center text-[#9746FE] text-[30px] md:text-[30px] 2xl:text-[65px] mt-[80px] flex-col md:flex-row gap-[80px] md:gap-0">
                <div className="flex flex-col justify-center items-center gap-3 relative">
                    <div className="absolute top-[-40px] text-nowrap">Token Launch</div>

                    <div className="flex items-center box w-[150px] h-[150px] relative border-[2px] border-dashed border-[#9746FE]">
                        <div className="flex flex-col gap-2">
                            
                            <div className="boxw-full h-full">
                                <img src="./images/launch.png" width={100} alt="" className="absolute w-full h-full top-0 object-cover"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Line />

                <div className="flex flex-col justify-center items-center gap-3 relative">
                    <div className="absolute top-[-40px] text-nowrap">Listing on CMC</div>

                    <div className="flex items-center box w-[150px] h-[150px] relative border-[2px] border-dashed border-[#9746FE]">
                        <div className="flex flex-col gap-2">
                            
                            <div className="boxw-full h-full">
                                <img src="./images/coinmarketcap.png" width={100} alt="" className="absolute w-full h-full top-0 object-cover"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Line />

                <div className="flex flex-col justify-center items-center gap-3 relative">
                    <div className="absolute top-[-40px] text-nowrap">Cex Listing</div>

                    <div className="flex items-center box w-[150px] h-[150px] relative border-[2px] border-dashed border-[#9746FE]">
                        <div className="flex flex-col gap-2">
                            
                            <div className="boxw-full h-full">
                                <img src="./images/poloniex.png" width={100} alt="" className="absolute w-full h-full top-0 object-cover"/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    </div>
}


export default Journey;