function AboutSection() {
    return <div className="relative" id="project">
        <div className="container">

            <div className="wrapper flex flex-c flex-wrap xl:flex-nowrap justify-center items-center xl:flex-col mt-[130px]">
                
                <div className="flex flex-col-reverse xl:flex-row items-center justify-start w-full">
             
                    <img className="max-w-[300px] md:max-w-[400px] 2xl:max-w-[500px] mt-[40px] mb-[40px] md:mt-0 md:mb-0 floating" src="./images/sunappa_pose.png" alt="" />

                    <div className="text text-[#6A6187] text-[30px] md:text-[35px] 2xl:text-[50px] w-full md:w-[800px] 2xl:w-[820px] mt-[-40px] 2xl:ml-0">
                        SUN APPA ($SAPPA) is your go-to meme token for a dose of crypto fun. Join our lively community, embrace the humor, and let’s make the crypto world a little brighter together!
                    </div>
                </div>
            </div>
        </div>
        
    </div>
}

export default AboutSection;